<template>
  <div id="dashboard-page">
    <!-- filter component  -->
    <b-card no-body>
      <!-- header component -->
      <b-card-header>
        <div>
          <!-- title -->
          <b-card-title class="d-flex align-items-center">
            <i class="bi bi-columns-gap"></i>
            <span style="margin-left: 5px"> Dashboard </span>
          </b-card-title>
        </div>
        <check-url-component class="ml-auto mr-1" />
        <!-- date picker -->
        <datepicker-component />
      </b-card-header>
    </b-card>
    <!-- scraper status statistics -->
    <b-card>
      <!-- title -->
      <b-card-title class="mb-1 d-flex align-items-center">
        <span style="margin-left: 5px">
          Jumlah Statistik Log Scraper per Status
        </span>
      </b-card-title>
      <!-- loader component -->
      <loader v-if="is_loading" />
      <!-- stats component -->
      <div v-else class="row">
        <!-- error status -->
        <div class="col-md-4 py-2">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-danger rounded-circle"
                style="width: 48px; height: 48px"
              >
                <span class="b-avatar-custom">
                  <i class="bi bi-x-circle fs-18"></i>
                </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">{{ stats.error }}</h4>
              <p class="card-text font-small-3 mb-0">Error Status</p>
            </div>
          </div>
        </div>
        <!-- missing class status -->
        <div class="col-md-4 py-2">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-warning rounded-circle"
                style="width: 48px; height: 48px"
              >
                <span class="b-avatar-custom">
                  <i class="bi bi-exclamation-circle fs-18"></i>
                </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stats.missing_class }}
              </h4>
              <p class="card-text font-small-3 mb-0">Missing Class Status</p>
            </div>
          </div>
        </div>
        <!-- fixing error status -->
        <div class="col-md-4 py-2">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-primary rounded-circle"
                style="width: 48px; height: 48px"
              >
                <span class="b-avatar-custom">
                  <i class="bi bi-tools fs-18"></i>
                </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stats.fixing_error }}
              </h4>
              <p class="card-text font-small-3 mb-0">Fixing Error Status</p>
            </div>
          </div>
        </div>
        <!-- redirected status -->
        <div class="col-md-4 py-2">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-info rounded-circle"
                style="width: 48px; height: 48px"
              >
                <span class="b-avatar-custom">
                  <i class="bi bi-repeat fs-18"></i>
                </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">{{ stats.redirected }}</h4>
              <p class="card-text font-small-3 mb-0">Redirected Status</p>
            </div>
          </div>
        </div>
        <!-- unavailable status -->
        <div class="col-md-4 py-2">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-secondary rounded-circle"
                style="width: 48px; height: 48px"
              >
                <span class="b-avatar-custom">
                  <i class="bi bi-slash-circle fs-18"></i>
                </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">{{ stats.unavailable }}</h4>
              <p class="card-text font-small-3 mb-0">Unavailable Status</p>
            </div>
          </div>
        </div>
        <!-- fixed status -->
        <div class="col-md-4 py-2">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <span
                class="b-avatar badge-light-success rounded-circle"
                style="width: 48px; height: 48px"
              >
                <span class="b-avatar-custom">
                  <i class="bi bi-check-circle fs-18"></i>
                </span>
              </span>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stats.fixed }}
              </h4>
              <p class="card-text font-small-3 mb-0">Fixed Status</p>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <!-- scraper graph -->
    <b-card>
      <!-- title -->
      <b-card-title class="mb-1 d-flex align-items-center">
        <span style="margin-left: 5px"> Grafik Log Scraper per Status </span>
      </b-card-title>
      <!-- loader component -->
      <loader v-if="is_loading" />
      <!-- linechart component -->
      <b-card-body v-else>
        <linechart-component :data="chart_data" />
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import useJwt from "@/auth/jwt/useJwt";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
} from "bootstrap-vue";
import linechart_component from "@/views/components/lineChart.vue";
import loader from "@/views/components/loader.vue";
import datepicker_component from "@/views/components/datePicker.vue";
import check_url_component from "@/views/components/checkURL.vue";
import axios from "axios";

export default {
  name: "Dashboard",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    "linechart-component": linechart_component,
    "datepicker-component": datepicker_component,
    "check-url-component": check_url_component,
    loader,
  },
  data() {
    return {
      is_loading: true,
      from_date: this.$route.query.from_date,
      to_date: this.$route.query.to_date,
      stats: {
        error: 0,
        unavailable: 0,
        redirected: 0,
        missing_class: 0,
        fixed: 0,
        fixing_error: 0,
      },
      chart_data: {
        colors: [
          "#ea5455",
          "#00cfe8",
          "#82868b",
          "#ff9f43",
          "#28c76f",
          "#7367f0",
        ],
        categories: [],
        series: [
          {
            name: "Error",
            data: [],
          },
          {
            name: "Redirected",
            data: [],
          },
          {
            name: "Unavailable",
            data: [],
          },
          {
            name: "Missing Class",
            data: [],
          },
          {
            name: "Fixed",
            data: [],
          },
          {
            name: "Fixing Error",
            data: [],
          },
        ],
      },
      cancelTokenStats: null,
      cancelTokenGraph: null,
    };
  },
  created() {
    this.getStats();
    this.getChart();
  },
  watch: {
    "$route.query"() {
      this.from_date = this.$route.query.from_date;
      this.to_date = this.$route.query.to_date;
      this.getStats();
      this.getChart();
    },
  },
  methods: {
    getChart() {
      this.is_loading = true;
      if (this.cancelTokenGraph) {
        this.cancelTokenGraph.cancel();
      }
      this.cancelTokenGraph = axios.CancelToken.source();
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "dashboard/charts-log?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelTokenGraph.token })
        .then((res) => {
          if (res.status == 200) {
            this.chart_data.categories = res.data.categories;
            for (let i = 0; i <= this.chart_data.series.length; i++) {
              this.chart_data.series[i].data = res.data.series[i].value;
            }
          } else {
            this.chart_data = {
              categories: [],
              series: [],
            };
            this.is_loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.chart_data.categories.length > 0) {
            this.is_loading = false;
          }
        });
    },
    getStats() {
      if (this.cancelTokenStats) {
        this.cancelTokenStats.cancel();
      }
      this.cancelTokenStats = axios.CancelToken.source();
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "dashboard/stats-log?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelTokenStats.token })
        .then((res) => {
          this.stats.error = res.data.error_log;
          this.stats.unavailable = res.data.unavaile_log;
          this.stats.redirected = res.data.redirect_log;
          this.stats.missing_class = res.data.miss_class_log;
          this.stats.fixed = res.data.fixed_log;
          this.stats.fixing_error = res.data.fixing_error_log;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
